

import {Component, Vue} from "vue-property-decorator";
import MakbuzIndirList from "@/components/lists/MakbuzIndirList.vue";
import UyapBagliDegilView from "@/views/eicrapro/UyapBagliDegilView.vue";

@Component({
  components: {UyapBagliDegilView, MakbuzIndirList}
})
export default class MakbuzIndirView extends Vue {
}
