
import { Component, Vue } from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import { IOdemeIslemi } from "@/plugins/uyap-plugin/uyap/OdemeIslemiSorgulama";
import { Task } from "@/plugins/uyap-plugin/store/modules/GorevYoneticisiTypes";
import { TaskTuru } from "@/plugins/uyap-plugin/enum/TaskTuru";
import MakbuzController from "@/plugins/uyap-plugin/makbuz/MakbuzController";
import { ProgramPaths } from "@/enum/ProgramPaths";
import Loading from "@/components/Loading.vue";

@Component({
  components: { Loading, Dates },
})
export default class MakbuzIndirList extends Vue {
  headers = [
    {
      text: "Dosya Esas Numarası",
      align: "start",
      sortable: true,
      value: "dosyaNo",
    },
    { text: "Birim Adı", value: "birimAdi" },
    { text: "Dosya Turu", value: "dosyaTurKod" },
    { text: "Tutar", value: "tutar" },
    { text: "Durum", value: "durum" },
    { text: "İşlem Tarihi", value: "uyapIslemTarihi" },
    { text: "Taraflar", value: "taraflar" },
    {
      text: "İşlemler",
      value: "actions",
      width: 50,
      align: "center",
      sortable: false,
    },
  ];
  data = {
    baslangicTarihi: "",
    bitisTarihi: "",
  };
  onFly = false;
  taskCreated = false;
  items: IOdemeIslemi[] = [];
  unfilteredItems: IOdemeIslemi[] = [];
  selectedItems: IOdemeIslemi[] = [];
  dosyaTurleri: string[] = [];
  selectedDosyaTuruFilter: string = "";
  sadeceBasarililarFilter: boolean = false;

  async fillRows() {
    try{
      this.onFly = true;
      const timeDiff = Math.abs(
          new Date(this.data.bitisTarihi).getTime() -
          new Date(this.data.baslangicTarihi).getTime()
      );
      const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
      if (diffDays <= 30) {
        let response = await this.$uyap.OdemeIslemiSorgulama().run({
          baslangicTarihi: this.$helper.changeUyapDateFormat(
              this.data.baslangicTarihi
          ),
          bitisTarihi: this.$helper.changeUyapDateFormat(this.data.bitisTarihi),
        });
        this.items = response;
        this.items = this.items.filter(
            (item: IOdemeIslemi) => typeof item.birimAdi != "undefined"
        );
        console.log("items", this.items);
        this.unfilteredItems = Object.assign([], this.items);
        this.dosyaTurleri = [];
        this.items.forEach((x) => {
          let exists = this.dosyaTurleri.find((y) => y == x.tutar);
          if (!exists) this.dosyaTurleri.push(x.tutar);
        });
        this.filterDosyaTurleri();
      } else {
        this.$toast.error("Tarih aralığını 30 günden fazla olamaz.");
      }
    }catch (e) {
      this.$toast.error(e.message);
    }finally {
      this.onFly = false;
    }

  }
  get selectedItemsCheckbox() {
    return this.selectedItems.length > 0;
  }

  filterDosyaTurleri() {
    if (this.selectedDosyaTuruFilter == "") {
      if (this.sadeceBasarililarFilter)
        this.items = this.unfilteredItems.filter(
          (item) => item.durum == "Başarılı"
        );
      else this.items = Object.assign([], this.unfilteredItems);
    } else {
      if (this.sadeceBasarililarFilter)
        this.items = this.unfilteredItems.filter(
          (item) =>
            item.tutar == this.selectedDosyaTuruFilter &&
            item.durum == "Başarılı"
        );
      else
        this.items = this.unfilteredItems.filter(
          (item) => item.tutar == this.selectedDosyaTuruFilter
        );
    }
  }

  async filterLastWeek() {
    await this.filterByDateMinus(7, 0);
  }

  async filterLastMonth() {
    await this.filterByDateMinus(30, 0);
  }

  async filterLast2Month() {
    await this.filterByDateMinus(60, 30);
  }

  async filterByDateMinus(startDayCount: number, finishDayCount: number) {
    let bugun: Date = new Date();
    let oncekiTarih: Date = new Date(bugun);
    oncekiTarih.setDate(bugun.getDate() - finishDayCount);
    this.data.bitisTarihi = oncekiTarih.toISOString().substr(0, 10);
    oncekiTarih = new Date(bugun);
    oncekiTarih.setDate(bugun.getDate() - startDayCount);
    this.data.baslangicTarihi = oncekiTarih.toISOString().substr(0, 10);
    await this.fillRows();
  }

  async createTask() {
    try {
      this.onFly = true;
      if (this.selectedItems.length == 0)
        throw new Error("İndirilecek makbuz/lar seçilmedi.");
      let tasks: Array<Task> = [];
      for (const item of this.selectedItems) {
        let task: Task = new Task();
        task.dosya_esas_no = item.dosyaNo ?? "";
        task.birim_adi = item.birimAdi;
        task.task_type_id = TaskTuru.makbuz_indir;
        task.data = item;
        tasks.push(task);
      }
      await this.$store.dispatch("postTasks", tasks);
      this.taskCreated = true;
    } catch (e: any) {
      this.$toast.error(e.message);
    } finally {
      this.onFly = false;
    }
  }

  async downloadSelected(item: IOdemeIslemi) {
    let fileName = item.dosyaNo + " makbuz (" + item.uyapId + ")";
    await MakbuzController.download(item.uyapId, item.uyapIslemTur, fileName);
  }

  sifirla() {
    this.selectedItems = [];
    this.data = {
      baslangicTarihi: "",
      bitisTarihi: "",
    };
    this.taskCreated = false;
  }

  selectAllToggle() {
    let secilebilirler = this.items.filter(
      (item: any) => item.durum == "Başarılı"
    );
    if (this.selectedItems.length == secilebilirler.length) {
      this.selectedItems = [];
    } else {
      this.selectedItems = secilebilirler;
    }
  }

  islemlereGit() {
    this.$router.push(ProgramPaths.eicrapro + "/islemler");
  }
}
